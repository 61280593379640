<template>
  <SignInLayout :subTitle="this.$t('login.subtitle', { brandName })" :title="this.$t('login.title')">
    <v-container class="start_content">
      <div>
        <v-tabs background-color="transparent" class="login_methods">
          <v-tab class="login_methods__tab" @click="method = 'email'">Email</v-tab>
          <v-tab class="login_methods__tab" @click="method = 'phone'">Phone</v-tab>
        </v-tabs>
        <v-row class="default-row-height">
          <v-col v-if="isEmailMethod" class="" cols="12" sm="12">
            <BaseInput
              v-model="email"
              :errors="emailErrors"
              :placeholder="$t('general.inputs.email')"
              fieldName="email"
              @blur="$v.email.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col v-if="!isEmailMethod" class="" cols="12" sm="12">
            <PhoneInput
              v-model="phoneNumber"
              :disabled="!!email.length"
              :errors="phoneNumberErrors"
              placeholder="Phone Number"
              @blur="$v.phoneNumber.$touch()"
              @change="onPhoneNumberChange"
            />
          </v-col>
          <div v-if="isEmailMethod" class="full-width">
            <v-col cols="12" sm="12">
              <PasswordInput
                v-model="password"
                :errors="passwordErrors"
                :hideDetails="true"
                :isPasswordHelper="true"
                :passwordRootChecks="passwordChecks"
                :placeholder="$t('general.inputs.password')"
                @blur="$v.password.$touch()"
                @change="onPasswordChange"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <PasswordInput
                v-model="confirmedPassword"
                :errors="confirmedPasswordErrors"
                :hideDetails="true"
                :placeholder="$t('general.inputs.confirmPassword')"
                @blur="$v.confirmedPassword.$touch()"
                @change="onConfirmedPasswordChange"
              />
            </v-col>
          </div>
          <v-col cols="12" sm="12">
            <DatePicker
              v-model="birthDate"
              :dense="true"
              :errors="birthDateErrors"
              :height="47"
              :hide-details="true"
              :maxDate="new Date().toISOString().substr(0, 10)"
              :placeholder="$t('general.inputs.dateOfBirth')"
              @blur="$v.birthDate.$touch()"
              @setDate="onBirthDateChange"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <BaseInput
              v-model="socialSecurityNumber"
              :errors="socialSecurityNumberErrors"
              :hide-details="true"
              fieldName="socialSecurityNumber"
              maskPattern="xxxx-xx-####"
              placeholder="Last 4 digits of Social Security Number"
              @blur="$v.socialSecurityNumber.$touch()"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <BaseInput
              v-model="code"
              :errors="codeErrors"
              :hide-details="true"
              fieldName="code"
              maskPattern="XXXX-XXXX"
              placeholder="Activation code"
              @blur="$v.code.$touch()"
              @change="onFieldChange"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <PrimaryButton
              id="sign-in-button"
              :loading="loading"
              :text="$t('login.button')"
              size="x-large"
              @onClick="submitCheck"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <Snackbar v-model="snackbar" :text="error" :timeout="4000" type="error" @toggleSnackbar="toggleSnackbar" />
  </SignInLayout>
</template>
<script>
import * as firebase from "firebase/app";
import moment from "moment-timezone";
import { mask } from "vue-the-mask";
import { required } from "vuelidate/lib/validators";

import { InvitesApi } from "@/api/invites";
import DatePicker from "@/components/shared/DatePicker";
import PasswordInput from "@/components/shared/PasswordInput";
import PhoneInput from "@/components/shared/PhoneInput";
import Snackbar from "@/components/shared/Snackbar";
import SignInLayout from "@/components/SignInLayout";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validations";

const codeLength = (value) => {
  return value.length === 9;
};
const passwordMatch = (value, vm) => {
  return value === vm.password;
};
const passwordValid = (value, vm) => {
  return vm.passwordChecks.some((pc) => pc.value);
};
const passwordMinLength = (value) => {
  return value.length >= 6;
};
export default {
  name: "StartPage",
  components: {
    PasswordInput,
    DatePicker,
    BaseInput,
    SignInLayout,
    PrimaryButton,
    Snackbar,
    PhoneInput,
  },
  validations() {
    const validations = {
      socialSecurityNumber: { required },
      code: { required, codeLength },
      birthDate: { required },
    };
    if (this.method === "email") {
      validations.password = { required, passwordValid, passwordMinLength };
      validations.confirmedPassword = { required, passwordMatch };
    } else {
      validations.phoneNumber = { required };
    }

    return validations;
  },
  directives: {
    mask,
  },
  data() {
    return {
      isDataVerified: false,
      method: "email",
      loading: false,
      brandName: process.env.VUE_APP_BRAND_NAME,
      email: "",
      phoneNumber: "",
      socialSecurityNumber: "",
      password: "",
      confirmedPassword: "",
      birthDate: null,
      code: "",
      showArrowUp: false,
      snackbar: false,
      error: "",
    };
  },
  computed: {
    maskPattern(value) {
      return value;
    },
    passwordChecks() {
      return [
        {
          value: this.password.length >= 8,
          text: this.$t("settings.changePassword.hint.length"),
          id: "characters",
        },
        {
          value: /(?=.*[a-z])(?=.*[A-Z])/.test(this.password),
          text: this.$t("settings.changePassword.hint.uppercase"),
          id: "letters",
        },
        {
          value: /(?=.*\d)/.test(this.password),
          text: this.$t("settings.changePassword.hint.number"),
          id: "digits",
        },
      ];
    },
    birthDateErrors() {
      const errors = [];
      if (!this.$v.birthDate.$dirty) return errors;
      !this.$v.birthDate.required && errors.push(this.$t("validation.required", { field: "Date of birth" }));
      return errors;
    },
    confirmedPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmedPassword.$dirty) return errors;
      !this.$v.confirmedPassword.passwordMatch && errors.push(this.$t("validation.passwordMatch"));
      !this.$v.confirmedPassword.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      return errors;
    },
    socialSecurityNumberErrors() {
      const errors = [];
      if (!this.$v.socialSecurityNumber.$dirty) return errors;
      !this.$v.socialSecurityNumber.required &&
        errors.push(this.$t("validation.required", { field: "Social security number" }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber) return errors;
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!isValidPhoneNumber(this.cleanPhone))
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
    cleanPhone() {
      if (!this.phoneNumber) return "";
      return this.phoneNumber.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email) return [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push(this.$t("validation.required", { field: "Activation Code" }));
      !this.$v.code.codeLength &&
        errors.push(this.$t("validation.required", { field: "Activation Code must have 8 letters" }));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      !this.$v.password.passwordMinLength && errors.push(this.$t("validation.passwordMinLength"));
      !this.$v.password.passwordValid && errors.push(this.$t("validation.passwordMustApply"));
      return errors;
    },
    isEmailMethod() {
      return this.method === "email";
    },
  },
  methods: {
    toggleSnackbar() {
      this.snackbar = !this.snackbar;
    },
    onPasswordChange(password) {
      this.password = password;
    },
    onConfirmedPasswordChange(password) {
      this.confirmedPassword = password;
    },
    async submitCheck() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      try {
        let body = {
          birthDate: moment(this.birthDate).utc(true).format("YYYY-MM-DD"),
          code: this.code.split("-").join(""),
          socialSecurityNumber: this.socialSecurityNumber.split("xxx-xx-")[1],
        };
        if (this.method === "email") {
          body = {
            ...body,
            password: this.password,
            repeat_password: this.confirmedPassword,
            email: this.email,
          };
        } else {
          body.phoneNumber = this.phoneNumber;
        }

        const data = await InvitesApi.activateLoginProfile(body);
        this.loading = false;
        if (data.uid) {
          this.gotoLogin();
        }
      } catch (err) {
        this.loading = false;
        this.snackbar = true;
        if (err?.message?.length && !err?.message.map) {
          this.error = err.message;
        } else if (err?.error?.length) {
          this.error = err?.error;
        }
      }
    },
    onBirthDateChange(value) {
      this.birthDate = value;
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onPhoneNumberChange(value) {
      this.phoneNumber = value;
    },

    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
  },
  beforeMount() {
    if (this.$route.query?.email && isValidEmail(this.$route.query.email)) {
      this.email = this.$route.query.email;
      this.method = "email";
    }
    if (this.$route.query?.phoneNumber) {
      this.phoneNumber = this.$route.query.phoneNumber;
      this.method = "phoneNumber";
    }
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.submitCheck();
      },
    });

    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.login_methods {
  width: 100%;
  margin-bottom: 30px;

  &__tab {
    width: 100%;
    text-transform: capitalize;
    font-size: 14px;
  }
}

.start {
  &_content {
    text-align: center;
    width: 100%;
    margin: 10px auto;
    max-width: $mobile-width;
  }

  * {
    font-size: 18px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
